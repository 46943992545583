import sbjs from 'sourcebuster'
import $ from 'jquery'

sbjs.init()

const getUTMParams = () => {
  const data = sbjs.get
  return {
    medium: data.current.mdm,
    source: data.current.src,
    type: data.current.typ,
    campaign: data.current.cmp,
  }
}

export const fillTrafficInfo = () => {
  const utmParams = getUTMParams()
  $('[data-traffic-medium]').val(utmParams.medium)
  $('[data-traffic-source]').val(utmParams.source)
  $('[data-traffic-type]').val(utmParams.type)
  $('[data-traffic-campaign]').val(utmParams.campaign)
}
